<template>
  <div>
     {{sLabel ==='' ? `Imagen` : sLabel}}
  </div>
</template>

<script>
import mixin from '@/mixins/BuildCorreoComponentes.js'
  export default {
    name:"Imagen",
    mixins:[mixin],
    data() {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>