<template>
  <div>
      {{sLabel ==='' ? `Logo` : sLabel}}
  </div>
</template>

<script>
import mixin from '@/mixins/BuildCorreoComponentes.js'
  export default {
    name:"Logo",
    mixins:[mixin],
    data() {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>