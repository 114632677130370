import { render, staticRenderFns } from "./DragComponent.vue?vue&type=template&id=6fb0315e&scoped=true&"
import script from "./DragComponent.vue?vue&type=script&lang=js&"
export * from "./DragComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fb0315e",
  null
  
)

export default component.exports