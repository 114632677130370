import { render, staticRenderFns } from "./subTitulo.vue?vue&type=template&id=44915fb5&scoped=true&"
import script from "./subTitulo.vue?vue&type=script&lang=js&"
export * from "./subTitulo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44915fb5",
  null
  
)

export default component.exports