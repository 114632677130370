<template>

  <!-- son los objetos que se arrastran -->
  
  <div class="mx-auto"
    draggable
    @drag="drag"
    @dragend="dragEnd"
    @dragover.prevent
    @dragenter.prevent >
    <slot/>
  </div>
</template>

<script>
export default {
  name:"DragComponent",
  props: {
    transferData: {
      type: Object,
      required: true
    },
  },  
  data() {
    return {
     mouseXY: {x: null, y: null},
    }
  },
  methods: {    
    drag(e) {
      this.ValidarPosicion(e, true)
    },
    dragEnd(e) {
      this.ValidarPosicion(e, false)

    },
    ValidarPosicion (e, enMovimiento){
      this.mouseXY.x = e.clientX !=0 ? e.clientX: this.mouseXY.x
      this.mouseXY.y = e.clientY !=0 ? e.clientY: this.mouseXY.y

      let parentRect = document.getElementById('content').getBoundingClientRect()

      // se valida que el mouse este dentro del rectangulo de la grid flex 
      if (((this.mouseXY.x > parentRect.left) && (this.mouseXY.x < parentRect.right)) && ((this.mouseXY.y > parentRect.top) && (this.mouseXY.y < parentRect.bottom))) {
        this.$emit('mouseXY', { mouseXY:this.mouseXY, transferData:this.transferData, enMovimiento }) // se meite la posicion y los datos del campo que se esta moviendo
      }
    },
    // dragStart(e) {
      // e.dataTransfer.effectAllowed = 'move'
      // e.dataTransfer.dropEffect = 'move'

      // e.dataTransfer.setData('payload', JSON.stringify(this.transferData))   // lo empleo para meter la iformación del componente que se esta moviendo 

    // }
  }
}
</script>

<style lang="css" scoped>
</style>
