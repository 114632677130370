// Esta llegando en el parametro del route una variable llamada oCorreo con esta estructura. json los datos son de ejemplo
// { "id_correos": "2", "nombre_correo": "Acta_Seguimiento", "blank_correo": "grid_Actas_seguimiento_Correo", 
// "descripcion_correo": "Se genera un Correo cuando se envia un acta de seguimiento", "frecuencia_dias": "0", "copia_gestor": "1", 
// "tipo": "Manual", "no_serie": "02", "nombre_tabla": "RiesgoNovedades" }
// tambien llega la constante idCorreos
<template>
    <div id="Board" class="board pa-1">
      <div class="my-2">
        <v-btn class="ma-2" outlined fab x-small color="primary" @click="Regresar">
          <v-icon small >mdi-undo</v-icon>
        </v-btn>
        <span class="pl-1 text-light font-size-2 titlesdark--text">Editando : {{$route.params.idCorreos}} - {{$route.params.oCorreo.nombre_correo}}</span>
      </div>
      <div id= "TableroInterno" class="d-flex d-flex-row boardColum cont-shadown-rounded" style="align-items: stretch;">
        <div
          class="columnForm" 
          :style="{width:'300px'}" >
          <div v-for="(oCampo, key ) in aCampos" :key="key">
            <!-- <h3 class="campo" @dragstart.self="dragStart" @drag="drag"  @dragend="dragend" draggable="true" unselectable="on"  :transferData="oCampo"><span  class="font-weight-medium titlesdark--text">{{oCampo.nombre}} </span></h3> -->
             <DragComponent
                class="campo"
                @mouseXY="pushCuadro"
                :transferData="oCampo" >
                  <h3><span  class="font-weight-medium titlesdark--text">{{oCampo.nombre}} </span></h3>
             </DragComponent>
          </div>
        </div>
        <div id="content"
          class="columnForm" 
          :style="{width:'750px'}" >
            <GridLayout ref="gridlayout" :layout.sync="layout"
              :col-num="12"
              :row-height="30"
              :is-draggable="true"
              :is-resizable="true"
              :responsive="false"
              :vertical-compact="false"
              :prevent-collision="true"
              :use-css-transforms="true">
                <GridItem :key="item.i" v-for="item in layout" :class="{'edit-item': item.enEdicion}" class="item-grid"
                  :minH="1"
                  :maxH="3"
                  :minW="1"
                  :x="item.x"
                  :y="item.y"
                  :w="item.w"
                  :h="item.h"
                  :i="item.i">
                  <div class="display-inline" >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">                    
                        <v-btn class="back-white btn-edit-drag ml-1" v-bind="attrs" v-on="on" color="primary" outlined width="20px" height="20px" fab @click="controlChange(item.i)">
                          <v-icon size="13px" class="btncolor--tex  mx-1">mdi-cog-outline</v-icon>
                        </v-btn> 
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="back-white btn-edit-drag ml-1" v-bind="attrs"  v-on="on" color="primary" outlined width="20px" height="20px" fab @click="removeItem(item.i)">
                          <v-icon size="13px" class="btncolor--text mx-1">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Borrar</span>
                    </v-tooltip> 
                  </div>
                  <v-divider vertical class="display-inline mx-1 primary drag-edit-divider" v-if="item.w >= 3"></v-divider>
                  <component class="display-inline text-label" :is="item.info.nombre" :sLabel="item.info.sLabel" :class="{'text-label-pq ml-1':item.w <= 2}"></component>
                  <v-icon v-if="item.enEdicion" x-small color="green" class="iconEdicion"> mdi-checkbox-blank-circle</v-icon>
                </GridItem>
            </GridLayout>
          <btnCancelarGuardar v-model="bGuardarFormato" @btnseleccion="cancelarGuardarFormato"/>
        </div>
        <!-- Se monta otra columan al lado del boadColumn  -->
        <div id="Configuracion" class="sidebar cont-shadown-rounded" style="align-items: stretch;">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <h3><span class="font-weight-regular titlesdark--text">Configura el campo seleccionado</span></h3>
              </v-col>
            </v-row>  
            <v-divider class="mt-2"></v-divider>
          </v-container>
          <div class="descripcion" >
            <h3 class="mb-2 text-medium text-primary font-normal">Descripción:</h3>
            <p class="p-nomargin">{{gettItemSeleccionado.descripcion}}</p>
          </div>
          <div class="ma-3">
            <!-- 1-> Tabla 2-> campo 3-> TextoLabel -->
            <CampoConfiguracion v-if="cmpLabel"
              Titulo="Label" 
              ToolTip="Es el label (nombre) con el cual se mostrara el Campo">
                <v-text-field v-model="sLabel" solo dense></v-text-field>
            </CampoConfiguracion>
            <CampoConfiguracion v-if="cmpTablaPrincipal"
              Titulo="Tabla Principal" 
              ToolTip="Es la tabla base del caso correo">
                <v-text-field v-model="sTablaBase" solo dense disabled></v-text-field>
            </CampoConfiguracion>
            <CampoConfiguracion v-if="cmpSelectTabla" 
              Titulo="Tabla" 
              ToolTip="Tabla de la cual se extraera la informacion">
                <v-select v-model="nTabla" :items="aTablas" label="Seleccione una Tabla" dense outlined item-text="texto" item-value="id" @change="changeTablas">
                </v-select>
            </CampoConfiguracion>
            <CampoConfiguracion v-if="cmpLogo" 
              Titulo="Parametro logo" 
              ToolTip="Seleccione el parametro del logo a emplear">
                <v-select v-model="nLogo" :items="aLogos" label="Seleccione un parametro" dense outlined  item-text="texto" item-value="id" @change="changeLogo">
                </v-select>
            </CampoConfiguracion>
            <CampoConfiguracion v-if="cmpCampo"
              Titulo="Nombre Campo" 
              :ToolTip="cmpTabla ? 'Campos a visualizar de la tabla seleccionada' : 'Campo de la tabla con la informacion - tal cual como esta en la tabla'">
                  <v-select v-model="sColumna" :items="aColumnas" label="Seleccione un campo" dense outlined :loading="bloadColuman" :multiple="cmpTabla && gettItemSeleccionado.tipo === 'Grid'"></v-select>
            </CampoConfiguracion>
            <CampoConfiguracion v-if="cmpTabla"
              Titulo="Campo relación Tabla Base" 
              ToolTip="Campo relacion de la tabla Principal">
               <v-select v-model="sColumnaRelacion" :items="aColumnasRelacion" label="Seleccione un campo" dense outlined></v-select>
            </CampoConfiguracion>
          </div>        
          <btnCancelarGuardar v-model="bGuardarConfig" @btnseleccion="cancelarGuardarConfig"/>
          <ErrorView v-model="bHayErro" :aErrores="aErrores"></ErrorView>
          <ModalMensaje v-model="bShowMensaje"  :nTipo="nTipo" :sMensaje="sMensaje"></ModalMensaje>
        </div>    
      </div>
    </div>    
</template>

<script>

import {GridLayout, GridItem} from "vue-grid-layout"
import DragComponent from "@/components/buildCorreo/DragComponent.vue"
import axiosServices from '@/services/axiosServices.js'
import { mapGetters } from 'vuex'
import Titulo from '@/components/buildCorreo/Componentes/Titulo.vue'
import subTitulo from '@/components/buildCorreo/Componentes/subTitulo.vue'
import Texto from '@/components/buildCorreo/Componentes/Texto.vue'
import Imagen from '@/components/buildCorreo/Componentes/Imagen.vue'
import Firma from '@/components/buildCorreo/Componentes/Firma.vue'
import Logo from '@/components/buildCorreo/Componentes/Logo.vue'
import Etiqueta from '@/components/buildCorreo/Componentes/Label.vue'
import Tabla from '@/components/buildCorreo/Componentes/Tabla.vue'
import Mapa from '@/components/buildCorreo/Componentes/Mapa.vue'
import CampoConfiguracion from '@/components/buildCorreo/CampoConfiguracion.vue'
import btnCancelarGuardar from '@/components/btnCancelarGuardar.vue'
import ErrorView from '@/components/ErrorView.vue'
import ModalMensaje from '@/components/ModalMensaje.vue'

const ancho = 4
const alto  = 1

export default {
  name:'buildCorreos',
  components: {
    GridLayout, 
    GridItem,
    DragComponent,
    Titulo,
    subTitulo,
    Texto,
    Imagen,
    Firma,
    Logo,
    Etiqueta,
    Tabla,
    Mapa,
    CampoConfiguracion,
    btnCancelarGuardar,
    ErrorView,
    ModalMensaje,
  },
  data() {
    return {
    // getitemSeleccionado = { descripcion: (...) ,idPDF_Componentes: (...),nombre: (...),tipo_relacion: (...)  }

      layout: [
          // {x: 0, y: 0, w: 2, h: 1, i: "0",enEdicion: false ,info:{}},  asi son los arreglo base para que se vean los cuadritos
      ],
      aCampos:[],
      nIndexElementos: 0,
      nIndexCampoSelect: null,     // se emplea para saber que indice de elemento seleccionado en edicion
      sLabel: '',
      nIDTablaBase: '',
      sTablaBase: '',
      nTabla: 0,
      sTabla: '',
      aTablas: [],
      sColumna: '',
      aColumnas: [],
      sColumnaRelacion: '',
      aColumnasRelacion: [],
      bloadColuman: false,
      bGuardarConfig: false, 
      bGuardarFormato: false, 
      resizeEvent:{},
      bHayErro: false,      // indica que hay un error en el momento de guardar cada registro o elemento.
      aErrores: [],         // el arreglo de los errores a mostrar 
      nTipo: 0,
      sMensaje: '',
      bShowMensaje: false,
      nLogo: 0,             // me indica cuando es tipo Logo que logo selecciono
      aLogos: [],           // arreglo de los parametros de logos que tengo para seleccionar
    }
  },
  async mounted () {
    this.layout  = await this.loadData()
    this.aCampos = await this.getCampos()
    this.aTablas = await this.getTablas()
    this.aLogos  = await this.getLogos()
    this.getTablaBase()
    this.aColumnasRelacion = await this.getColumnasRelacion() 
    this.$store.dispatch('BuildCorreos/SetItemSeleccionado', {})

  },
  methods: {
    pushCuadro({mouseXY, transferData, enMovimiento}){
      const CampoMoviendose = `${this.nIndexElementos+1}.${transferData.nombre}`

      if(this.layout.findIndex(item => item.i === CampoMoviendose) === -1 ){
        this.layout.push({x: 1, y: 1, w: ancho, h: alto, i: CampoMoviendose, enEdicion: false , info : {...transferData}})
      } 
      
       this.$refs.gridlayout.dragEvent('dragstart', CampoMoviendose, mouseXY.x , mouseXY.y, alto, ancho)  // muestra el cuadrito rojo por debajo 
      

      // se prepara las cordenadas para actulizar la posicion del componete 
      const index = this.layout.length - 1
      const parentRect = document.getElementById('content').getBoundingClientRect()
      const el = this.$refs.gridlayout.$children[index]
      const new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
      
      //Actualiza la posicion del componente
      this.layout[index].x = new_pos.x  
      this.layout[index].y = new_pos.y
      
      if(!enMovimiento) this.nIndexElementos++   // Cuando deja de Movese incremento el numero de elementos que tengo
    },
    removeItem(item_i){
      // antes de borrar quito el que este seleccionado
      if(this.nIndexCampoSelect != null) this.layout[this.nIndexCampoSelect].enEdicion = false
      const index = this.layout.map(item => item.i).indexOf(item_i)
      this.layout.splice(index, 1)
      
      // como tengo la variable nIndexCampoSelect y si esta esta en el ultimo apuntando al ultimo elemento de la ista cuando borro uno este elemento deja de existi
      // Por lo cual posiciono el indice en donde corresponde.
      const NoRegistros = this.layout.length
      switch (NoRegistros) {
        case 0:
        case 1:
          this.nIndexCampoSelect =0
        break;
      
        default:
          this.nIndexCampoSelect = (index -1) > 0 ? index -1 : 0
        break;
      }

    },
    async getCampos() {
      const campos = await axiosServices.getConfiguration('/build_correo/campos', true)
      return campos.aData
    },
    async getTablas() {
      const campos =  await axiosServices.getConfiguration('/build_correo/tablas', true )
      return campos.aData
    },
    async getColumnasRelacion() {
      const campos =  await axiosServices.getConfiguration(`/build_correo/columnas/${this.nIDTablaBase}`, true ) 
      return campos.aData
    },
    async getLogos() {
      const campos =  await axiosServices.getConfiguration('/build_correo/infologo', true )
      return campos.aData
    },
    async getColumnas(tabla) {
      if( tabla != '' && tabla != null && !!tabla){ 
        const campos = await axiosServices.getConfiguration(`/build_correo/columnas/${tabla}`, true)
        return campos.aData
      }
      
      return []

    },
    async loadData(){
      const campos = await axiosServices.getConfiguration(`/build_correo/info/${this.$route.params.idCorreos}`, true)
      this.nIndexElementos = campos.aData.length + 1
      return campos.aData
    },
    async controlChange(item_i) {
      this.cleanAllConfig()
      if(this.nIndexCampoSelect != null) this.layout[this.nIndexCampoSelect].enEdicion = false

      this.nIndexCampoSelect = this.layout.map(item => item.i).indexOf(item_i) 
      this.layout[this.nIndexCampoSelect].enEdicion = true
      this.$store.dispatch('BuildCorreos/SetItemSeleccionado', this.layout[this.nIndexCampoSelect].info)
      // cargo la info si la tengo
      this.sLabel = this.layout[this.nIndexCampoSelect].info.sLabel  
      this.nTabla = this.layout[this.nIndexCampoSelect].info.nTabla  
      
      // cuando llega y no se ha guardado nada y el campo requiere tabla base cargo la tabla base
      if(this.nTabla === undefined ){
        if(this.cmpTablaPrincipal) {
          this.getTablaBase()
        } else{
          this.nTabla = 0 
        }
      }

      if(this.nTabla != '' && this.nTabla != null && this.nTabla != 0) { 
        this.sTabla = this.aTablas.find(value => value.id === this.nTabla).texto 
        this.bloadColuman = true
        if(!this.cmpLogo) this.aColumnas = await this.getColumnas(this.nTabla)
        this.bloadColuman = false
        if(this.cmpLogo ){
          // this.aLogos = await this.getLogos()
          this.nLogo = this.nTabla
        }      
      }  

      this.sColumna = this.layout[this.nIndexCampoSelect].info.sColumna 
      this.sColumnaRelacion = this.layout[this.nIndexCampoSelect].info.sColumnaRelacion 
    },
    async changeTablas(){
      this.bloadColuman = true
      this.sTabla = this.aTablas.find(value => value.id === this.nTabla).texto 
      this.aColumnas = await this.getColumnas(this.nTabla)
      this.bloadColuman = false
    },
    changeLogo (){
      this.sColumna = this.aLogos.find(value => value.id === this.nLogo).Observacion
      this.nTabla = this.nLogo
    },
    cancelarGuardarConfig(btnSelect){
      switch (btnSelect) {
        case 'cancelar':
          this.cleanAllConfig()
        break;
        case 'guardar':
          this.GuardarConfig()
        break;
      }
    },
    cancelarGuardarFormato(btnSelect){
      switch (btnSelect) {
        case 'cancelar':
          this.cleanAllFormato()
        break;
        case 'guardar':
          this.GuardarFormato()
        break;
      }
    },
    async cleanAllConfig(){
      this.sLabel =  ''
      this.sTabla =  ''
      this.sColumna =  ''
      this.sColumnaRelacion =  ''
      this.bloadColuman =  false
      this.nTabla = 0
      this.nLogo = 0
      
    },
    GuardarConfig(){
      this.bGuardarConfig = true

      this.bHayErro = this.validarConfig()
     
      if(this.nIndexCampoSelect != null && !this.bHayErro){
        this.layout[this.nIndexCampoSelect].info.sLabel = this.sLabel  
        this.layout[this.nIndexCampoSelect].info.nTabla = this.nTabla  
        this.layout[this.nIndexCampoSelect].info.sColumna = this.sColumna 
        this.layout[this.nIndexCampoSelect].info.sColumnaRelacion = this.sColumnaRelacion 
      }
      this.bGuardarConfig = false

    },
    validarConfig(){
      let aux_rsp = false
      this.aErrores = []
      
      if(this.cmpErroLabel) {
        this.aErrores.push('Se requiere un label')
        aux_rsp = true
      }

      if(this.cmpSelectTabla && this.cmpErrorTablaPrincipal){
        this.aErrores.push('Seleccione una Tabla')
        aux_rsp = true
      }

      if(this.cmpCampo && this.cmpErrorColuman) {
        this.aErrores.push('Selecciones un Campo')
        aux_rsp = true
      }

      if(this.cmpErrorLogo && this.cmpLogo) {
        this.aErrores.push('Debe indicar un Parametro para el logo')
        aux_rsp = true
      }

      if(this.cmpErrorRelacion && this.cmpTabla) {
        this.aErrores.push('Debe indicar un campo relación')
        aux_rsp = true
      }

      return aux_rsp    // no hay error

    },
    cleanAllFormato(){
      this.layout = []
      this.nIndexCampoSelect = null
      this.nIndexElementos = 0
    },
    async GuardarFormato(){
      this.bGuardarFormato = true

      if(this.validarFormato()){
        this.bHayErro = true
        this.bGuardarFormato = false
        return
      }
      

      const peticion = await axiosServices.postConfiguration(`/build_correo/${this.$route.params.idCorreos}`, this.layout, true)
      
      if(peticion.nStatusCode != 200){
        this.sMensaje = 'Error Almacenando'
        this.nTipo = 3
        this.bShowMensaje = true
      } else {
        this.sMensaje = 'Proceso Exitoso'
        this.nTipo = 1
        this.bShowMensaje = true
      }


      this.bGuardarFormato = false
    },
    validarFormato(){
      this.aErrores = []
      // recorro todo antes de cuardar validandando que todo tenga label
      const aux_elemento = this.layout.find(elemento => !(elemento.info.sLabel != '' && elemento.info.sLabel != null))

      if(this.layout.length == 0) {
        this.aErrores.push('Requiere tener algun componente!')
        return true         
      }

      if(aux_elemento != undefined ){
        this.aErrores.push('Algun elemento no esta guardado aún, por favor valide !')
        return true 
      } 

      return false
    },
    getTablaBase(){
      const aux = this.aTablas.find( elemento => elemento.nombre_tabla == this.$route.params.oCorreo.nombre_tabla )
      this.nIDTablaBase = aux.id
      this.sTablaBase = aux.texto
      this.nTabla = aux.id
      this.changeTablas()
    },
    Regresar(){
      this.$router.replace({name: 'CasosCorreos'})
    },
    changeOrigenInfo(){
      this.nTabla = this.nOrigenInfo
      this.changeTablas()
    },
    
    
  },
  computed: {
    // getitemSeleccionado = { descripcion: (...) ,idPDF_Componentes: (...),nombre: (...),tipo_relacion: (...)  }

    cmpTablaPrincipal(){
      return this.gettItemSeleccionado.tipo === 'Texto'   
    },
    cmpLabel(){
      return this.gettItemSeleccionado.tipo 
    },
    cmpSelectTabla(){
      return !this.cmpTablaPrincipal && (this.gettItemSeleccionado.tipo_relacion === "2" || this.gettItemSeleccionado.tipo_relacion === "1") && !this.cmpLogo
    },
    cmpCampo(){
      return (this.gettItemSeleccionado.tipo_relacion === "2" || this.gettItemSeleccionado.tipo_relacion === "1") && !this.cmpLogo  
    },
    cmpTabla(){
      return this.gettItemSeleccionado.tipo === 'Grid' || (this.gettItemSeleccionado.tipo === 'Imagen' && (this.nIDTablaBase != this.nTabla)) ||(this.gettItemSeleccionado.tipo === 'Firma' && (this.nIDTablaBase != this.nTabla))
    },
    cmpLogo(){
      return this.gettItemSeleccionado.tipo === 'Logo'
    },
    cmpErroLabel(){
      return this.sLabel == '' || this.sLabel == null 
    },
    cmpErrorTablaPrincipal(){
      return this.nTabla <= 0 
    },
    cmpErrorColuman(){
      return this.sColumna == '' || this.sColumna == null
    },
    cmpErrorLogo(){
      return this.nLogo <= 0 
    },
    cmpErrorRelacion(){
      return this.sColumnaRelacion == '' || this.sColumnaRelacion == null
    },

    ...mapGetters('BuildCorreos', ['gettItemSeleccionado'])
    
  },
}
</script>

<style lang="scss" scoped>
// OJo los height debe ser iguales en casi todos menos en el vue-grid-layout
  .board {
    width:100%;
    height: 90vh; 
  }

  .boardColum {
    overflow: auto;
    padding: 1rem;
  }

  .columnForm {
    padding: 0.5rem;
    margin-right: 1rem;
    /* min-width: 500px; */
    text-align: left;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: .25rem;
    background: #E5F3F9;
    color:#064b83;
    height: 90vh;
    // overflow: auto;
  } 

 .sidebar {
    background: white;
    width:30%;
    height: 90vh; 
    overflow: auto;
    padding: 10px 0px;
    color:#064b83;
    background: #E5F3F9;
  }  

  .vue-grid-layout {
    background: white;
    min-height: 70% !important;
    // height: 98% !important;
  } 

  .campo {
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: white;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    color:black;
    font-size: 14px;
    color:#0E69B0;
    border: solid 1px #E4E4E4;
    border-radius: 10px;
    &:hover{
      cursor: pointer;
      transform: scale(0.98);
    }
  }

  .descripcion {
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #f7fafc;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    color:black;
    font-size: 14px;
    margin:10px;
    height: 25%;
    overflow: auto;
  }

  .vue-grid-item  {
    background: #D9E6F4;
  }
  .iconEdicion {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
  }
  // ELEMENTO EN EDICIÓN
  .item-grid{
    border-radius: 3px;
    overflow: hidden;
  }
  .edit-item{
    border: solid 1px green;
    box-shadow: 0px 0px 3px rgba($color: greenyellow, $alpha: 0.7);
  }

  .display-inline{
    display: inline-block;
    vertical-align: middle;
  }
  .text-label{
    font-size: 1em;
  }
  .text-label-pq{
    font-size: 0.7em;
  }

</style>