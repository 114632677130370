<template>
  <v-row no-gutters align="center" justify="end" class="mb-0 pa-2 mt-3" >
    <v-col cols="12" class="text-center">
      <v-btn 
        class="rounded-lg mx-2"
        small
        outlined
        color="btncolor"
        @click="$emit('btnseleccion', 'cancelar')">
        Cancelar
      </v-btn>
      <v-btn
        class=" mx-3 white--text rounded-lg " 
        small
        color="btncolor"
        :loading="value"
        @click="guardar">
        Guardar
      </v-btn>
    </v-col>
  </v-row> 
</template>

<script>
  export default {
    name:"btnCancelarGuardar",
    props: {
      value: {      // se emplea par decir que esta guardando
        type: Boolean,
        default: false
      },
    },
    methods: {
      guardar() {
        // this.$emit('input', true)
        this.$emit('btnseleccion', 'guardar')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>