<template>
  <div class="my-0 white rounded-5 pa-2 my-2">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="mb-2 elemento-form">
          <span v-bind="attrs" v-on="on" class="text-medium text-primary font-normal elemento-form">{{Titulo}}</span>
        </div>
      </template>
      <span v-html="ToolTip"></span>
    </v-tooltip>   
    <slot></slot>   
  </div>
</template>

<script>
  export default {
    name:"CampoConfiguracion",
    props: {
      Titulo: {
        type: String,
        default: 'Sin Titulo'
      },
      ToolTip: {
        type: String,
        default: 'Sin Información'
      }
    },
  }
</script>

<style lang="scss" scoped>
.elemento-form{
  &:hover{
    cursor: pointer;
  }
}
</style>